import React, { useState, ReactElement } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { sample, get, includes, map, isEmpty } from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { TextField, Button, Grid, Typography, CircularProgress, Hidden } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from 'pages/_app';
import { splashImages } from 'lib/constants';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    minHeight: '100vh',
  },
  image: {
    display: 'table-cell',
    position: 'relative',
  },
  form: {
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: '0.5rem 0',
    },
  },
  formText: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
    },
  },
  toggleMode: {
    cursor: 'pointer',
  },
});

const validationSchema = yup.object({
  username: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function Login(): ReactElement {
  const [image] = useState(sample(splashImages));

  const classes = useStyles();
  const router = useRouter();

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await signInWithEmailAndPassword(
          firebaseAuth,
          get(values, 'username'),
          get(values, 'password'),
        );
      } catch (error) {
        console.error(error);
        const code = get(error, 'code');
        const message = get(error, 'message');
        setFieldError(includes(code, 'password') ? 'password' : 'username', message);
      }
    },
  });

  const errors = map(get(formik, 'errors'), val => val);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Hidden xsDown>
        <Grid item className={classes.image} xs={12} sm={5}>
          <Image
            src={image.url}
            alt={image.alt}
            objectFit="cover"
            objectPosition="center"
            layout="fill"
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={7}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Image
            src="/assets/logos/gradient.png"
            alt="SkinKitz Logo"
            width={7 * 16}
            height={7 * 16}
            layout="fixed"
          />
          <Typography variant="h6">Log In</Typography>
          <TextField
            variant="outlined"
            autoComplete="username"
            id="username"
            name="username"
            label="Email"
            type="text"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            className={classes.formText}
          />
          <TextField
            variant="outlined"
            autoComplete="current-password"
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            className={classes.formText}
          />
          <Typography
            className={classes.toggleMode}
            variant="body1"
            onClick={() => {
              if (!formik.isSubmitting) router.push('/forgotten-password');
            }}
          >
            Forgotten Password?
          </Typography>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting ? <CircularProgress size={16} /> : <Check />}
          >
            Sign In
          </Button>
          {!isEmpty(errors) &&
            map(errors, (error, i) => (
              <Typography key={i} variant="body1" color="error">
                {error}
              </Typography>
            ))}
        </form>
      </Grid>
    </Grid>
  );
}
